import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorker from "./serviceWorker";
import { StateProvider } from "./StateProvider";
import reducer, { initialState } from "./reducer";
import { Route, BrowserRouter as Router, Routes } from "react-router";
import Docs from "./screens/Docs";

if (process.env.NODE_ENV !== "development") {
  console.log = () => {};
  console.error = () => {};
}
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <StateProvider initialState={initialState} reducer={reducer}>
    <Router>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="docs/:docId" element={<Docs />} />
        <Route path="/docs/" element={<Docs />} />
        <Route path="*" element={<App />} />
      </Routes>
    </Router>
  </StateProvider>
);

reportWebVitals();
serviceWorker.unregister();
