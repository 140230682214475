import TrendingTab from "./tabs/TrendingTab";
import LatestTab from "./tabs/LatestTab";
import MostReadTab from "./tabs/MostReadTab";
import BookmarkTab from "./tabs/BookmarkTab";
import WatchlistNewsTab from "./tabs/WatchlistNewsTab"
import Login from "./Login";
import "./styles/NewsTab.css";
import { useStateValue } from "../StateProvider";

function NewsTab({mobileTab}) {
  const [{ tab }, dispatch] = useStateValue();

  const renderTabScreen = () => {
    switch (tab) {
      case 1:
        return <TrendingTab />;
        break;

      case 2:
        return <LatestTab />;
        break;

      case 3:
        return <MostReadTab />;
        break;

      case 4:
        return <BookmarkTab />;
        break;

      case 5:
        return <WatchlistNewsTab />
      
      case 7:
        return <Login />

      default:
        break;
    }
  };
  return <>{renderTabScreen()}</>;
}

export default NewsTab;
