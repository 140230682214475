import React from "react";

export default function ShareButton() {
  return (
    <div className="font-sans text-gray-800 leading-relaxed">
      <header className="mb-8">
        <h1 className="text-3xl font-bold text-gray-900">
          Share Button Integration Guide
        </h1>
        <p className="text-sm font-medium mt-2">
          <strong>Effective Date: December 30, 2024</strong>
        </p>
      </header>

      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-2">Getting Started</h2>
        <p>
          To integrate the OpinionTrade widget into your website or app, you can
          use the following code snippet:
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-2">Integration Code</h2>
        <pre
          className="bg-gray-100 p-4 rounded border"
          style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}
        >
          <code>
            {`<div class="opiniontrade-widget" data-app-id="YOUR_APP_ID"></div>`}
            {"\n"}
            {`<script src="https://www.opiniontrade.ai/share-widget.js"></script>`}
          </code>
        </pre>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-2">How to Use</h2>
        <ol className="list-decimal ml-6">
          <li>
            Replace <code>YOUR_APP_ID</code> with your company's name.
          </li>
          <li>
            Include the script file at the bottom of your HTML file to
            initialize the widget.
          </li>
        </ol>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-2">Customization</h2>
        <p>
          You can customize the widget's appearance and behavior using
          additional attributes:
        </p>
        <ul className="list-disc ml-6">
          <li>
            <code>data-variant</code>: Defines the button type (
            <code>"small"</code> or <code>"big"</code>).
          </li>
          <li>
            <code>data-width</code>: Sets the width of the button image (aspect
            ratio is maintained).
          </li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-2">Example</h2>
        <pre
          className="bg-gray-100 p-4 rounded border"
          style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}
        >
          <code>
            {`<div class="opiniontrade-widget" data-app-id="ABC Company" data-variant="big" data-width="300px"></div>`}
            {"\n"}
            {`<script src="https://www.opiniontrade.ai/share-widget.js"></script>`}
          </code>
        </pre>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-2">Support</h2>
        <p>
          If you need assistance, contact us at{" "}
          <a
            href="mailto:support@opiniontrade.com"
            className="text-blue-500 underline"
          >
            support@opiniontrade.com
          </a>
          .
        </p>
      </section>
    </div>
  );
}
