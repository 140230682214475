import { createRef, useEffect, useState, useRef } from "react";
import { authentication } from "../firebase";
import { useStateValue } from "../StateProvider";
import PhoneInput from "react-phone-number-input";
import "react-phone-input-2/lib/bootstrap.css";
import {
  inMemoryPersistence,
  RecaptchaVerifier,
  setPersistence,
  signInWithPhoneNumber,
} from "firebase/auth";
import { actionTypes } from "../reducer";
import appStoreBadge from "../../src/assets/app_store_badge.svg";
import { onValue, ref, set, update, get, child } from "firebase/database";
import { database } from "../firebase";
import qrCode from "../../src/assets/qr_with_phone.png";
import OTPInput from "otp-input-react";
import { isMobile } from "react-device-detect";
import MarketMovers from "../components/MarketMovers";
import Sidebar from "./Sidebar";
import Header from "../components/Header";
import { grey } from "@mui/material/colors";
import trendingImage from "../../src/assets/trending.jpeg";
import watchlistImage from "../../src/assets/watchlist.jpeg";


function Login() {
  const [{ user, chat, loginClicked, config, send }, dispatch] = useStateValue();
  const [numberloading, setnumberloading] = useState(false);
  const [codereceived, setcodereceived] = useState(false);
  const [codeloading, setcodeloading] = useState(false);
  const [loginclicked, setloginclicked] = useState(false);
  const [phonenumber, setphonenumber] = useState("");
  const [code, setcode] = useState("");
  const [mostactive, setmostactive] = useState([]);
  const [spy, setspy] = useState({});
  const [dia, setdia] = useState({});
  const [logincount, setLoginCount] = useState(0);
  const [submitcount, setSubmitCount] = useState(0);
  let captcha = createRef();
  const [checked, setChecked] = useState(true);
  const [warning, setWarning] = useState("");
  const [storedsymbols, setstoredsymbols] = useState([]);
  const listInnerRef = useRef();

  const handleCheckboxChange = () => {
    setChecked(!checked);
  };

  useEffect(() => {
      if (user) {
        const watchlistInfo = ref(
          database,
          `/users/${user.phoneNumber}/watchlist`
        );
        onValue(
          watchlistInfo,
          (snapshot) => {
            if (!snapshot.exists) {
              setstoredsymbols([]);
            } else {
              let snapVal = snapshot.val();
              if (snapVal !== undefined && snapVal !== null) {
                setstoredsymbols(Object.keys(snapVal));
              }
            }
          },
          (err) => {
            console.log("error getting watchlist", err);
            setstoredsymbols([]);
          }
        );
      }
    }, [user]);

  // Load count from Firebase when the component mounts
  useEffect(() => {
    const fetchCount = async () => {
      const countRef = ref(database, "/webanalytics/LoginClickCount");
      const snapshot = await get(countRef);

      if (snapshot.exists()) {
        setLoginCount(snapshot.val());
      } else {
        console.log("Count does not exist in Firebase.");
      }
    };

    fetchCount();
  }, []);

  console.log("logincount", logincount);

  // Load count from Firebase when the component mounts
  useEffect(() => {
    const fetchCount = async () => {
      const countRef = ref(database, "/webanalytics/SubmitClickCount");
      const snapshot = await get(countRef);

      if (snapshot.exists()) {
        setSubmitCount(snapshot.val());
      } else {
        console.log("Count does not exist in Firebase.");
      }
    };

    fetchCount();
  }, []);

  console.log("submitcount", submitcount);

  useEffect(() => {
    getMostActive();
    getSPY();
    getDIA();
  }, []);

  const getMostActive = () => {
    fetch(`${process.env.REACT_APP_SERVER_ADDRESS}/opinion/v1/mostactive`)
      .then((data) => data.json())
      .then((datajson) => {
        setmostactive(datajson);
      });
  };

  const getSPY = () => {
    fetch(
      `${process.env.REACT_APP_SERVER_ADDRESS}/opinion/v1/getStockQuote?ticker=SPY`
    )
      .then((data) => data.json())
      .then((datajson) => {
        setspy(datajson);
      });
  };

  const getDIA = () => {
    fetch(
      `${process.env.REACT_APP_SERVER_ADDRESS}/opinion/v1/getStockQuote?ticker=DIA`
    )
      .then((data) => data.json())
      .then((datajson) => {
        setdia(datajson);
      });
  };

  const signIn = () => {
    setnumberloading(true);
    window.recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback: (response) => {},
      },
      authentication
    );
    onSignInSubmit();
  };

  const onSignInSubmit = async () => {
    const appVerifier = window.recaptchaVerifier;
    const number = phonenumber;
    /*
    const userRef = ref(database, `/users/${number}`); 
    const snapshot = await get(userRef);
    if (!snapshot.exists()) {
      const isAndroid = /Android/i.test(navigator.userAgent);
      const message = `Download OpinionTrade \n\n - Shared By OpinionTrade App\n\nhttp://web.opiniontrade.ai/share/http://www.opiniontrade.ai`
      const smsUri = isAndroid ? `sms:${number}?body=${encodeURIComponent(message)}` : `sms:/open?addresses=${number}&body=${encodeURIComponent(message)}`
      window.open(smsUri, '_self'); 
    }else{
      console.log("User already exists in Firebase, no SMS sent.");
    }
    */
    setPersistence(authentication, inMemoryPersistence)
      .then(() => {
        return signInWithPhoneNumber(authentication, number, appVerifier)
          .then((confirmationResult) => {
            window.confirmationResult = confirmationResult;
            // Use update to modify only specific fields
            update(ref(database, `/users/${number}/UserStateMachine`), {
              EnteredPhoneNumberonWeb: true,
            });

            update(ref(database, `/users/${number}`), {
              createdAt: new Date().toISOString(),
              WebOTPInput: false,
            });
            setcodereceived(true);
          })
          .catch((error) => {
            console.log("error", error);
          });
      })
      .catch((error) => {
        console.log("err", error);
      });
  };

  const onVerifyCodeSubmit = (event) => {
    let confirmationResult = window.confirmationResult;
    setcodeloading(true);
    setPersistence(authentication, inMemoryPersistence)
      .then(() => {
        return confirmationResult
          .confirm(code)
          .then((result) => {
            const user = result.user;
            console.log("log user", user);
            if (user) {
              const userRef = ref(database, `/users/${user.phoneNumber}`);
              get(child(userRef, 'firstLoggedIn')).then((snapshot) => {
                if (!snapshot.exists()) {
                  update(userRef, {
                    firstLoggedIn: new Date().toISOString(),
                  });
                }
              });
              // Use update to modify specific fields within UserStateMachine
              update(
                ref(database, `/users/${user.phoneNumber}/UserStateMachine`),
                {
                  LoggedinOnWeb: true,
                  ConvertedLoggedinOnWebByAffilitaedMarketing: false,
                }
              ); 
              update(ref(database, `/users/${user.phoneNumber}`), {
                WebOTPInput: true,
                groupMessage: true,
                groupNewsCard: true,
                peerMessage: true,
                peerNewsCard: true,
                sound: true,
              })
                .then(() => {
                  console.log("User settings updated successfully!");
                })
                .catch((error) => {
                  console.error("Error updating user settings:", error);
                });
              dispatch({
                type: actionTypes.SET_USER,
                user: user.toJSON(),
              });
              localStorage.setItem("user", JSON.stringify(user));
              if (isMobile){
                dispatch({
                  type: actionTypes.SET_TAB,
                  tab: 1,
                })
              }
            }
          })
          .catch((error) => {
            console.error("Error while checking the verification code", error);
          });
      })
      .catch((error) => {
        console.log("err", error);
      });
  };

  const renderSPY = () => {
    let changePercent = (spy.changePercent * 100).toFixed(2);
    let isUp = changePercent > 0;
    return (
      <li
        className={`flex flex-col transition ${
          isUp ? "bg-[#fff]" : "bg-[#fff]"
        } rounded-md mb-3 group mr-2 flex-1 p-2 w-[156px] h-[130px] border-[1px] border-[#dadce0]`}
      >
        <div className="flex flex-1 flex-col justify-start items-start">
          <div
            className={`text-xs text-white ${
              isUp ? "bg-[#e6f4ea]" : "bg-[#fce8e6]"
            } rounded-[5px] px-2 py-1 font-bold`}
          >
            <p className={`${isUp ? "text-[#137333]" : "text-[#c5221f]"}`}>
              {spy.symbol}
            </p>
          </div>
          <h3 className={`text-[.875rem] font-semibold text-[#000] mr-3`}>
            S&P 500
          </h3>
        </div>
        <div className="flex flex-1 flex-col justify-end items-start mt-auto">
          <p className="font-bold text-[1rem] text-black">
            ${(spy.latestPrice * 10).toFixed(2)}
          </p>
          <div
            className={`rounded-[8px] px-[8px] ${
              isUp ? "bg-[#e6f4ea]" : "bg-[#fce8e6]"
            }`}
          >
            <p
              className={`text-[16px] font-bold tracking-[.00625em] ${
                isUp ? "text-[#137333]" : "text-[#a50e0f]"
              }`}
            >
              {changePercent}%
            </p>
          </div>
        </div>
      </li>
    );
  };

  const renderDIA = () => {
    let changePercent = (dia.changePercent * 100).toFixed(2);
    let isUp = changePercent > 0;
    return (
      <li
        className={`flex flex-col transition bg-[#fff] rounded-md mb-3 group flex-1 p-2 w-[156px] h-[130px] border-[1px] border-[#dadce0]`}
      >
        <div className="flex flex-1 flex-col justify-start items-start">
          <div
            className={`text-xs text-white ${
              isUp ? "bg-[#e6f4ea]" : "bg-[#fce8e6]"
            } rounded-[5px] px-2 py-1 font-bold`}
          >
            <p className={`${isUp ? "text-[#137333]" : "text-[#c5221f]"}`}>
              {dia.symbol}
            </p>
          </div>
          <h3 className="text-[.875rem] font-semibold text-black mr-3">
            Dow Jones
          </h3>
        </div>
        <div className="flex flex-1 flex-col justify-end items-start">
          <p className="font-bold text-[1rem] text-black">
            ${(dia.latestPrice * 100).toFixed()}
          </p>
          <div
            className={`rounded-[8px] px-[8px] ${
              isUp ? "bg-[#e6f4ea]" : "bg-[#fce8e6]"
            }`}
          >
            <p
              className={`text-[16px] font-bold tracking-[.00625em] ${
                isUp ? "text-[#137333]" : "text-[#a50e0f]"
              }`}
            >
              {changePercent}%
            </p>
          </div>
        </div>
      </li>
    );
  };


  useEffect(() => {
    if (loginClicked === true) {
      setloginclicked(loginClicked);
    }
  });

  const onScroll = (e) => {
    const currentScroll = e.target.scrollTop;
    setScrollPosition(currentScroll);

    // Trigger login prompt on scroll past a certain point, e.g., 100px
    if (!user && currentScroll > 500) {
      setShowLoginPrompt(true);
    }

    if (user) {
      if (listInnerRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
        if (scrollTop + clientHeight === scrollHeight && hasMoreNews) {
          setShowFetchMore(true);
          setPageNumber(pageNumber + 1);
          getMoreNews();
        }
      }
    }
  };

  if (isMobile){
    return (
      <div
      className={`flex-1 scrollbar bg-white border-r-0 sm:border-r-[5px] cursor-pointer ${
        user ? "" : "sm:rounded-r-3xl"
      } ${
        user
          ? send.enabled
            ? "overflow-y-hidden"
            : "overflow-y-hidden"
          : "overflow-y-hidden"
      }`}
      style={{
        maxHeight: "100vh",
        overflowY: /*user ? */ "scroll" /*: "hidden"*/,
      }}
      onScroll={onScroll}
      ref={listInnerRef}
    >
        <MarketMovers storedsymbols={storedsymbols} />
        <Sidebar mobile={true} />
        {user ? <Header
          title="Download our app"
          icon={
            <svg
              height="25px"
              width="25px"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 384 512"
              fill={
                config.typeofFirebaseDB !== "production" ? "#0d5446" : "#d54446"
              }
            >
              <path d="M384 319.1C384 425.9 297.9 512 192 512s-192-86.13-192-192c0-58.67 27.82-106.8 54.57-134.1C69.54 169.3 96 179.8 96 201.5v85.5c0 35.17 27.97 64.5 63.16 64.94C194.9 352.5 224 323.6 224 288c0-88-175.1-96.12-52.15-277.2c13.5-19.72 44.15-10.77 44.15 13.03C215.1 127 384 149.7 384 319.1z" />
            </svg>
          }
        /> : (
          null
      )}

        {user && <div className="flex flex-row justify-center items-center mt-4 space-x-4">
          <img src={trendingImage} className="w-1/4 h-auto rounded-lg" />
          <div className="flex flex-col space-y-4 items-center">
            <button
              className="bg-[#1ab394] text-white px-6 py-2 rounded-xl font-bold flex items-center"
              onClick={() => window.location.href = "https://apps.apple.com/us/app/opiniontrade/id1530699357"}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#000" className="apple-logo mr-2">
                <path d="M16.46 5.79l.3.01a5.6 5.6 0 0 1 4.38 2.38c-.1.07-2.62 1.53-2.59 4.57.04 3.63 3.19 4.84 3.22 4.86-.02.08-.5 1.72-1.66 3.41-1 1.46-2.04 2.92-3.67 2.95-1.6.03-2.13-.96-3.96-.96-1.84 0-2.42.93-3.94.99-1.57.06-2.78-1.58-3.78-3.04-2.07-2.98-3.64-8.42-1.53-12.1a5.87 5.87 0 0 1 4.97-3c1.55-.03 3.01 1.04 3.96 1.04.95 0 2.73-1.29 4.6-1.1zM16.78 0a5.3 5.3 0 0 1-1.25 3.83 4.46 4.46 0 0 1-3.56 1.7 5.03 5.03 0 0 1 1.27-3.71A5.38 5.38 0 0 1 16.78 0z"/>
              </svg>
              IOS
            </button>
            <button
              className="bg-[#1ab394] text-white px-6 py-2 rounded-xl font-bold flex items-center"
              //onClick={() => window.location.href = "https://play.google.com/store/apps/details?id=com.opiniontrade"}
            >
              <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24" height="24" viewBox="0 0 48 48" className="google-play-logo mr-2">
                <path fill="#4db6ac" d="M7.705,4.043C7.292,4.15,7,4.507,7,5.121c0,1.802,0,18.795,0,18.795S7,42.28,7,43.091c0,0.446,0.197,0.745,0.5,0.856l20.181-20.064L7.705,4.043z"></path><path fill="#dce775" d="M33.237,18.36l-8.307-4.796c0,0-15.245-8.803-16.141-9.32C8.401,4.02,8.019,3.961,7.705,4.043l19.977,19.84L33.237,18.36z"></path><path fill="#d32f2f" d="M8.417,43.802c0.532-0.308,15.284-8.825,24.865-14.357l-5.601-5.562L7.5,43.947C7.748,44.038,8.066,44.004,8.417,43.802z"></path><path fill="#fbc02d" d="M41.398,23.071c-0.796-0.429-8.1-4.676-8.1-4.676l-0.061-0.035l-5.556,5.523l5.601,5.562c4.432-2.559,7.761-4.48,8.059-4.653C42.285,24.248,42.194,23.5,41.398,23.071z"></path>
              </svg>
              Android 
              Coming soon
            </button>
          </div>
          <img src={watchlistImage} className="w-1/4 h-auto rounded-lg" />
        </div>}
        <div ref={(ref) => (captcha = ref)}>
          <div id="recaptcha-container"></div>
        </div>
        {!user ? (
          codereceived ? (
            <div className="flex flex-col justify-start items-center min-h-screen bg-gray-100">
              <div className="bg-white rounded-3xl shadow-lg p-8 w-[90%] max-w-md flex flex-col justify-start items-center">
              <div
                className="h-16 w-16 rounded-full overflow-hidden relative flex justify-center p-3 cursor-pointer absolute right-[10px] top-[10px]"
                onClick={() => {
                  setloginclicked(false);
                  setcodereceived(false);
                  setnumberloading(false);
                  setphonenumber("");
                  const appVerifier = window.recaptchaVerifier;
                  window.recaptchaVerifier = appVerifier.clear();
                  captcha.innerHTML = '<div id="recaptcha-container"></div>';
                  window.confirmationResult = "";
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 320 512"
                  fill="#000000"
                  className="w-6 h-6"
                >
                  <path d="M310.6 361.4l-22.6 22.6c-4.7 4.7-12.3 4.7-17 0L160 272 39 384c-4.7 4.7-12.3 4.7-17 0l-22.6-22.6c-4.7-4.7-4.7-12.3 0-17l160-160c4.7-4.7 12.3-4.7 17 0l160 160c4.7 4.7 4.7 12.3 0 17z"/>
                </svg>
              </div>
              <h2 className="font-bold text-2xl text-black mb-6">Enter OTP</h2>
              <OTPInput
                value={code}
                onChange={setcode}
                autoFocus
                OTPLength={6}
                otpType="number"
                disabled={false}
                inputStyles={{
                  width: 40,
                  height: 50,
                  borderRadius: 5,
                  fontSize: 24,
                  color: 'black',
                  backgroundColor: grey[200],
                }}
                style={{ marginLeft: '25px' }}
              />
              <button onClick={onVerifyCodeSubmit} className="bg-[#1ab394] text-white px-6 py-2 rounded-xl mt-4 font-bold">
                {codeloading ? (
                  <div role="status" className="flex justify-center">
                    <svg
                      aria-hidden="true"
                      className="w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  "Join"
                )}
              </button>
            </div>
            </div>
          ) : ( 
            <div className="flex flex-col justify-start -mt-5 items-center bg-white p-6 rounded-lg ">
              <div className="bg-white rounded-3xl shadow-lg p-8 w-[100%] max-w-md flex flex-col justify-start items-center border-2 border-gray-300 text-center">
              <Header 
                title="Welcome! 🌟 Join The First AI POWERED Social Network For Investors."
              />
              <div className="justify-start items-center mt-5" style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'flex-start', marginTop: '30px' }}>
                <PhoneInput
                  defaultCountry="US"
                  countries={['US']}
                  international={false}
                  countryCallingCodeEditable={false}
                  value={phonenumber}
                  onChange={(phone) => setphonenumber(phone)}
                  inputComponent="input"
                  inputProps={{
                    name: 'phone',
                    required: true,
                    autoFocus: true, 
                    autoComplete: 'tel',
                  }}
                  style={{
                    borderRadius: '5px',
                    flex: 1,
                    backgroundColor: numberloading ? "#cacaca" : "#ffffff",
                  }}
                  disabled={numberloading}
                />
                <style>
                {`
                  .PhoneInputCountryIcon {
                    width: 50px !important; /* Adjust the width as needed */
                    height: 50px !important; /* Adjust the height as needed */
                    margin-bottom: -15px;
                  }
                  .PhoneInputCountrySelect {
                    display: none;
                  }
                  .PhoneInput {
                    display: flex;
                    align-items: center;
                  }
                  .PhoneInputInput {
                    flex: 1;
                    padding: 5px;
                    border: 1px solid #ccc;
                    border-radius: 5px;
                  }
                  .PhoneInputCountry {
                    margin-right: 10px;
                    
                  }
                `}
              </style>
              </div>
              <button onClick={signIn} className="bg-[#1ab394] text-white px-6 py-2 mt-5 rounded-xl mb-8 font-bold w-full">
                {numberloading ? (
                  <div role="status" className="flex justify-center">
                    <svg
                      aria-hidden="true"
                      className="w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  "Join"
                )}
              </button>
              <div className="rounded-lg overflow-hidden mt-4" style={{ marginTop: '-15px' }}>
                <iframe
                  width="320"
                  height="auto"
                  style={{ borderRadius: '0.5rem' }}
                  src="https://www.youtube.com/embed/L5rzuRqjtgY"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
              </div>
            </div>
          )
        ) : null}
      </div>
    );
  }

  return (
    <div
      className={`sm:flex hidden w-full px-8 ${
        loginclicked ? "w-[500px] justify-center items-center" : "w-[300px]"
      } transition-width transition-slowest ease`}
    >
      <div ref={(ref) => (captcha = ref)}>
        <div id="recaptcha-container"></div>
      </div>
      {loginclicked ? (
        codereceived ? (
          <div className="flex flex-col justify-start">
            <div
              className="h-16 w-16 rounded-full overflow-hidden realtive flex justify-center p-3 cursor-pointer absolute right-[10px] top-[10px]"
              onClick={() => {
                setloginclicked(false);
                setcodereceived(false);
                setcodereceived(false);
                setnumberloading(false);
                setphonenumber("");
                const appVerifier = window.recaptchaVerifier;
                window.recaptchaVerifier = appVerifier.clear();
                captcha.innerHTML = '<div id="recaptcha-container"></div>';
                window.confirmationResult = "";
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 320 512"
                fill="#fff"
              >
                <path d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
              </svg>
            </div>
            <div className="flex flex-col items-center justify-center h-screen -mt-[-100]">
              <h1 className="font-bold text-3xl text-black mb-6 ">Enter OTP</h1>
              <OTPInput
                value={code}
                onChange={setcode}
                autoFocus
                OTPLength={6}
                otpType="number"
                disabled={false}
                inputStyles={{
                  width: 50,
                  height: 50,
                  borderRadius: 5,
                  fontSize: 24,
                }}
              />
              <button
                className="bg-[#1ab394] text-white px-6 py-2 rounded-xl mt-4 font-bold "
                onClick={() => onVerifyCodeSubmit()}
              >
                {codeloading ? (
                  <div role="status" className="flex justify-center">
                    <svg
                      aria-hidden="true"
                      className="w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  "Join"
                )}
              </button>
            </div>
          </div>
        ) : (
          <div className="flex flex-col mt-[-200px]">
            <div
              className="h-16 w-16 rounded-full overflow-hidden realtive flex justify-center p-3 cursor-pointer absolute right-[10px] top-[10px]"
              onClick={() => {
                dispatch({
                  type: actionTypes.SET_LOGIN_CLICKED,
                  loginClicked: false,
                });
                setloginclicked(false);
                setcodereceived(false);
                setcodereceived(false);
                setnumberloading(false);
                setphonenumber("");
                const appVerifier = window.recaptchaVerifier;
                window.recaptchaVerifier = appVerifier.clear();
                captcha.innerHTML = '<div id="recaptcha-container"></div>';
                window.confirmationResult = "";
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 320 512"
                fill="#000"
              >
                <path d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
              </svg>
            </div>
            <h1 className="font-bold text-xl text-black mb-6">
              Enter Your Phone (USA only)
            </h1>
            <PhoneInput
                  defaultCountry="US"
                  countries={['US']}
                  international={false}
                  countryCallingCodeEditable={false}
                  value={phonenumber}
                  onChange={(phone) => setphonenumber(phone)}
                  inputComponent="input"
                  inputProps={{
                    name: 'phone',
                    required: true,
                    autoFocus: true, 
                    autoComplete: 'tel',
                  }}
                  style={{
                    borderRadius: '5px',
                    flex: 1,
                    backgroundColor: numberloading ? "#cacaca" : "#F3F4F6",
                  }}
                  disabled={numberloading}
                />
                <style>
                {`
                  .PhoneInputCountryIcon {
                    width: 50px !important; /* Adjust the width as needed */
                    height: 50px !important; /* Adjust the height as needed */
                  }
                  .PhoneInputCountrySelect {
                    border: none;
                    background-color: #F3F4F6;
                  }
                  .PhoneInput {
                    display: flex;
                    align-items: center;
                  }
                  .PhoneInputInput {
                    flex: 1;
                    padding: 5px;
                    border: 1px solid #ccc;
                    border-radius: 5px;
                    background-color: #F3F4F6;
                  }
                  .PhoneInputCountry {
                    margin-right: -60px;
                    margin-bottom: 12px;
                  }
                `}
              </style>
            <button
              className="bg-[#1ab394] text-white px-6 py-2 rounded-xl mt-4 font-bold"
              onClick={() => {
                //setSubmitCount(0)

                setSubmitCount((prevCount) => {
                  const newCount = prevCount + 1;

                  // Update Firebase with the new count
                  update(ref(database, `/webanalytics/`), {
                    SubmitClickCount: newCount,
                  })
                    .then(() => {
                      console.log("Firebase updated successfully");
                    })
                    .catch((error) => {
                      console.error("Error updating Firebase:", error);
                    });

                  return newCount; // Ensure the state is updated correctly
                });
                if (checked) {
                  setWarning("");
                  signIn();
                } else {
                  setWarning(
                    "Please accept the Terms and Conditions before submitting."
                  );
                }
              }}
            >
              {numberloading ? (
                <div role="status" className="flex justify-center">
                  <svg
                    aria-hidden="true"
                    className="w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                "Submit"
              )}
            </button>
            {warning && (
              <p style={{ color: "red", fontSize: "14px" }}>{warning}</p>
            )}
            <div
              style={{ display: "flex", alignItems: "center", marginTop: 8 }}
            >
              <input
                type="checkbox"
                checked={checked}
                onChange={handleCheckboxChange}
                style={{ marginRight: "8px" }}
              />
              <span>
                I accept the{" "}
                <a
                  href="/docs/terms-conditions"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "#007bff", textDecoration: "none" }}
                >
                  Terms and Conditions
                </a>
                .
              </span>
            </div>
          </div>
        )
      ) : (
        <div className="flex flex-col w-full">
          <div className="flex flex-1 flex-col">
            <div className="w-full pt-4">
              <p className="font-semibold text-xs text-gray-500">
                Contact us: contact@opiniontrade.ai
              </p>
            </div>
            <div className="flex mb-3 mt-[20px]">
              {renderSPY()}
              {renderDIA()}
            </div>
            <div className="border-t-[2px] border-[#e5e7eb] ">
              <p className="font-handwritten text-[25px] text-black pt-2 text-center ">
                Download our app
              </p>
              <div className="flex">
                <div className="flex flex-1 justify-center items-center space-x-4">
                  <a
                    href="https://apps.apple.com/us/app/opiniontrade/id1530699357"
                    target="_blank"
                  >
                    <img
                      src={qrCode}
                      loading="lazy"
                      style={{
                        width: "200px",
                        height: "200px",
                      }}
                      className="h-auto w-[250px] object-contain cursor-pointer"
                    />
                  </a>
                </div>
              </div>
            </div>
            <div className="flex flex-1 flex-col justify-center items-center mb-6 mt-[-300px]">
              <button
                className="bg-[#1ab394] text-white px-6 py-2 rounded-xl mt-4 font-bold"
                onClick={() => {
                  //setLoginCount(0)

                  setLoginCount((prevCount) => {
                    const newCount = prevCount + 1;

                    // Update Firebase with the new count
                    update(ref(database, `/webanalytics/`), {
                      LoginClickCount: newCount,
                    })
                      .then(() => {
                        console.log("Firebase updated successfully");
                      })
                      .catch((error) => {
                        console.error("Error updating Firebase:", error);
                      });

                    return newCount; // Ensure the state is updated correctly
                  });

                  setloginclicked(true);
                }}
              >
                Login
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Login;
