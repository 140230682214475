import React from "react";

export default function TermsConditions() {
  return (
    <div className="font-sans text-gray-800 leading-relaxed">
      <header className="mb-8">
        <h1 className="text-3xl font-bold text-gray-900">
          Terms and Conditions for OpinionTrade
        </h1>
        <p className="text-sm font-medium mt-2">
          <strong>Effective Date: July 12, 2024</strong>
        </p>
      </header>

      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-2">1. Introduction</h2>
        <p>
          Welcome to OpinionTrade! These Terms and Conditions govern your use of
          the OpinionTrade app and its associated services. By accessing or
          using the app, you agree to be bound by these terms.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-2">2. Services Provided</h2>
        <p>
          OpinionTrade allows users to share financial news cards between mobile
          devices and desktops, facilitating social interactions within the
          investment community. The app is provided at no cost and is intended
          for use as is.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-2">3. User Obligations</h2>
        <p>
          You agree to use OpinionTrade only for lawful purposes and in a way
          that does not infringe the rights of, restrict or inhibit anyone
          else's use and enjoyment of the app. Prohibited behavior includes
          harassing or causing distress or inconvenience to any other user,
          transmitting obscene or offensive content, or disrupting the normal
          flow of dialogue within OpinionTrade.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-2">4. Privacy Policy</h2>
        <p>
          Your privacy is important to us. Please review our Privacy Policy,
          which also governs your use of OpinionTrade, to understand our
          practices.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-2">5. Intellectual Property</h2>
        <p>
          All content included on OpinionTrade, such as text, graphics, logos,
          images, as well as the compilation thereof, and any software used on
          the site, is the property of OpinionTrade or its suppliers and
          protected by copyright and other laws that protect intellectual
          property and proprietary rights.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-2">
          6. Disclaimer of Warranties
        </h2>
        <p>
          Your use of OpinionTrade is at your sole risk. The service is provided
          on an "AS IS" and "AS AVAILABLE" basis. The service is provided
          without warranties of any kind, whether express or implied, including,
          but not limited to, implied warranties of merchantability, fitness for
          a particular purpose, non-infringement, or course of performance.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-2">
          7. Limitation of Liability
        </h2>
        <p>
          In no event shall OpinionTrade or its directors, employees, partners,
          agents, suppliers, or affiliates, be liable for any indirect,
          incidental, special, consequential, or punitive damages, including
          without limitation, loss of profits, data, use, goodwill, or other
          intangible losses, resulting from (i) your access to or use of or
          inability to access or use the service; (ii) any conduct or content of
          any third party on the service; (iii) any content obtained from the
          service; and (iv) unauthorized access, use or alteration of your
          transmissions or content, whether based on warranty, contract, tort
          (including negligence) or any other legal theory, whether or not we
          have been informed of the possibility of such damage.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-2">8. Governing Law</h2>
        <p>
          These Terms shall be governed and construed in accordance with the
          laws of the United States, without regard to its conflict of law
          provisions.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-2">9. SMS Opt in</h2>
        <p>
          By providing your phone number and opting in on our app and site, you
          consent to receive SMS and/or email communications related to our
          services, promotions, and updates. Message frequency may vary.
          Standard message and data rates may apply. You may opt out of
          receiving messages at any time by following the unsubscribe
          instructions provided in the communication or by contacting us
          directly. Opting out will not affect your account or access to our
          services. OpinionTrade.ai is not responsible for delays or failures in
          message delivery caused by third-party carriers or communication
          platforms. By agreeing to receive messages, you acknowledge that you
          have read and understood these Terms and Conditions and accept full
          responsibility for complying with your carrier’s policies and any
          applicable regulations. For more information, please contact us at{" "}
          <a
            href="mailto:contact@opiniontrade.ai"
            className="text-blue-500 underline"
          >
            contact@opiniontrade.ai
          </a>
          .
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-2">10. Changes to Terms</h2>
        <p>
          We reserve the right, at our sole discretion, to modify or replace
          these Terms at any time. If a revision is material, we will provide at
          least 30 days' notice prior to any new terms taking effect. What
          constitutes a material change will be determined at our sole
          discretion.
        </p>
      </section>

      <section>
        <h2 className="text-xl font-semibold mb-2">11. Contact Us</h2>
        <p>
          If you have any questions about these Terms, please contact us at{" "}
          <a
            href="mailto:contact@opiniontrade.ai"
            className="text-blue-500 underline"
          >
            contact@opiniontrade.ai
          </a>
          .
        </p>
      </section>
    </div>
  );
}
