import { onValue, ref, set, update, get } from "firebase/database";
import { useEffect, useState } from "react";
import { database } from "../firebase";
import { useStateValue } from "../StateProvider";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { actionTypes } from "../reducer";
import NewsStockSlider from "../components/NewsStockSlider";

function Watchlist() {
  const [watchlist, setwatchlist] = useState();
  const [contacts, setcontacts] = useState();
  const [{ user, chat, tab }, dispatch] = useStateValue();
  const [loading, setloading] = useState(true);
  const [spy, setspy] = useState({});
  const [dia, setdia] = useState({});

  useEffect(() => {
    const userInfo = ref(database, `/users/${user.phoneNumber}/watchlist`);
    onValue(
      userInfo,
      (snapshot) => {
        if (!snapshot.exists) {
          setwatchlist([]);
          setloading(false);
        } else {
          let snapVal = snapshot.val();
          if (snapVal) {
            let allSymbols2 = [];
            let allSymbols = Object.values(snapVal);
            let allNews = Object.values(snapVal).map(
              (item) => Object.values(item)[0]
            );
            let storedSymbols = [];
            allSymbols.forEach((item) => {
              const symbol = Object.keys(item); // Access stock data
              allSymbols2.push(symbol);
              if (Object.values(item)[0].enabled) {
                storedSymbols.push(Object.values(item)[0].symbol);
              }
            });
            let url = `${
              process.env.REACT_APP_SERVER_ADDRESS
            }/opinion/v1/stocksQuote?ticker=${allSymbols2.join("&ticker=")}`;
            fetch(url)
              .then((data) => data.json())
              .then((dataJson) => {
                let dataJSON2 = dataJson;
                dataJSON2.map((news2, index2) => {
                  dataJson[index2] = {
                    ...news2,
                    enabled: allNews[index2].enabled,
                  };
                });
                setwatchlist(dataJson);
                setloading(false);
              })
              .catch((err) => {
                setwatchlist(allNews);
                setloading(false);
              });
          } else {
            setwatchlist([]);
            setloading(false);
          }
        }
      },
      (error) => {
        console.log("no watchlist", error);
        setwatchlist([]);
        setloading(false);
      }
    );
    getSPY();
    getDIA();
  }, []);

  function deleteWatchlist(stock) {
    const userWatchlistPath = `/users/${user.phoneNumber}/watchlist`;

    // Get the current watchlist
    const watchlistRef = ref(database, userWatchlistPath);
    get(watchlistRef)
      .then((snapshot) => {
        const watchlist = snapshot.val();

        // If the watchlist exists and is an array, filter out the stock with the matching symbol
        if (watchlist && Array.isArray(watchlist)) {
          const updatedWatchlist = watchlist.filter(
            (item) => Object.keys(item)[0] !== stock.symbol
          );

          // Update the watchlist in Firebase after removing the stock
          return set(watchlistRef, updatedWatchlist);
        } else {
          console.log("Watchlist is either empty or not in expected format");
        }
      })
      .then(() => {
        console.log("Stock deleted successfully from watchlist");
      })
      .catch((error) => {
        console.error("Error deleting stock:", error);
      });
    dispatch({
      type: actionTypes.CLEAR_SELECTED_STOCK,
      stock: stock,
    });
  }

  const getSPY = () => {
    fetch(
      `${process.env.REACT_APP_SERVER_ADDRESS}/opinion/v1/getStockQuote?ticker=SPY`
    )
      .then((data) => data.json())
      .then((datajson) => {
        setspy(datajson);
      });
  };

  const getDIA = () => {
    fetch(
      `${process.env.REACT_APP_SERVER_ADDRESS}/opinion/v1/getStockQuote?ticker=DIA`
    )
      .then((data) => data.json())
      .then((datajson) => {
        setdia(datajson);
      });
  };

  const renderSPY = () => {
    let changePercent = (spy.changePercent * 100).toFixed(2);
    let isUp = changePercent > 0;
    return (
      <li
        className={`flex flex-col transition ${
          isUp ? "bg-[#fff]" : "bg-[#fff]"
        } rounded-md mb-3 group mr-2 flex-1 p-2 w-[156px] h-[130px] border-[1px] border-[#dadce0]`}
      >
        <div className="flex flex-1 flex-col justify-start items-start">
          <div
            className={`text-xs text-white ${
              isUp ? "bg-[#e6f4ea]" : "bg-[#fce8e6]"
            } rounded-[5px] px-2 py-1 font-bold`}
          >
            <p className={`${isUp ? "text-[#137333]" : "text-[#c5221f]"}`}>
              {spy.symbol}
            </p>
          </div>
          <h3 className={`text-[.875rem] font-semibold text-[#000] mr-3`}>
            S&P 500
          </h3>
        </div>
        <div className="flex flex-1 flex-col justify-end items-start mt-auto">
          <p className="font-bold text-[1rem] text-black">
            ${(spy.latestPrice * 10).toFixed(2)}
          </p>
          <div
            className={`rounded-[8px] px-[8px] ${
              isUp ? "bg-[#e6f4ea]" : "bg-[#fce8e6]"
            }`}
          >
            <p
              className={`text-[16px] font-bold tracking-[.00625em] ${
                isUp ? "text-[#137333]" : "text-[#a50e0f]"
              }`}
            >
              {changePercent}%
            </p>
          </div>
        </div>
      </li>
    );
  };

  const renderDIA = () => {
    let changePercent = (dia.changePercent * 100).toFixed(2);
    let isUp = changePercent > 0;
    return (
      <li
        className={`flex flex-col transition bg-[#fff] rounded-md mb-3 group flex-1 p-2 w-[156px] h-[130px] border-[1px] border-[#dadce0]`}
      >
        <div className="flex flex-1 flex-col justify-start items-start">
          <div
            className={`text-xs text-white ${
              isUp ? "bg-[#e6f4ea]" : "bg-[#fce8e6]"
            } rounded-[5px] px-2 py-1 font-bold`}
          >
            <p className={`${isUp ? "text-[#137333]" : "text-[#c5221f]"}`}>
              {dia.symbol}
            </p>
          </div>
          <h3 className="text-[.875rem] font-semibold text-black mr-3">
            Dow Jones
          </h3>
        </div>
        <div className="flex flex-1 flex-col justify-end items-start">
          <p className="font-bold text-[1rem] text-black">${dia.latestPrice}</p>
          <div
            className={`rounded-[8px] px-[8px] ${
              isUp ? "bg-[#e6f4ea]" : "bg-[#fce8e6]"
            }`}
          >
            <p
              className={`text-[16px] font-bold tracking-[.00625em] ${
                isUp ? "text-[#137333]" : "text-[#a50e0f]"
              }`}
            >
              {changePercent}%
            </p>
          </div>
        </div>
      </li>
    );
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = list ? Array.from(list) : [];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const addSymbolAsKeyToArray = (watchlist) => {
    return watchlist.map((stock) => {
      return { [stock.symbol]: { ...stock } }; // Create a new object with symbol as the key
    });
  };

  const onDragEnd = (result) => {
    const userWatchlistPath = `/users/${user.phoneNumber}/watchlist`;
    const watchlistInfo = ref(database, userWatchlistPath);
    // Dropped outside the list
    if (!result.destination) {
      return;
    }

    const reorderedWatchlist = reorder(
      watchlist,
      result.source.index,
      result.destination.index
    );
    setwatchlist(reorderedWatchlist);
    console.log("reorder", reorderedWatchlist);
    const updatedWatchlist = addSymbolAsKeyToArray(reorderedWatchlist);
    set(
      ref(database, `/users/${user.phoneNumber}/watchlist`),
      updatedWatchlist
    );
  };

  const getNews = (stock) => {
    console.log("stockky wockyyy", stock);
    dispatch({
      type: actionTypes.SET_TAB,
      tab: 5,
    });
    dispatch({
      type: actionTypes.SET_SELECTED_STOCK,
      stock: stock,
    });
  };

  if (loading) {
    return (
      <div className="pt-3 overflow-y-scroll p-5 w-[300px] pt-0">
        <div className="flex flex-1 pt-7 pb-5 w-full sticky top-0 z-50 items-center">
          <h1 className="font-bold text-2xl text-black mt-1">
            Smart Watchlist
          </h1>
        </div>
        <div className="flex border-b-[2px] border-[#e5e7eb] mb-3">
          {renderSPY()}
          {renderDIA()}
        </div>
        {/* <h1 className="font-bold text-2xl text-white mt-5">Watchlist</h1> */}
        {loading && (
          <div role="status" className="flex justify-center m-10">
            <svg
              aria-hidden="true"
              className="mr-2 w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
            <span className="sr-only">Loading...</span>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="flex flex-col">
      <div className="flex-1 overflow-y-scroll p-5 w-[300px] pt-0 z-[100]">
        <div className="flex flex-1 pt-7 pb-5 w-full sticky top-0 z-50 items-center backdrop-blur-sm">
          <h1 className="font-bold text-2xl text-black mt-1">
            Smart Watchlist
          </h1>
        </div>
        <div className="flex border-b-[2px] border-[#e5e7eb] mb-3">
          {renderSPY()}
          {renderDIA()}
        </div>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="watchlist">
            {(provided) => (
              <ul
                {...provided.droppableProps}
                ref={provided.innerRef}
                className="watchlist"
              >
                {watchlist &&
                  watchlist.length > 0 &&
                  watchlist.map((stock, index) => {
                    let changePercent = (stock.changePercent * 100).toFixed(2);
                    let isUp = changePercent > 0;

                    return (
                      <Draggable
                        key={stock.symbol}
                        draggableId={stock.symbol}
                        index={index}
                      >
                        {(provided) => (
                          <li
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={`flex flex-row transition ${
                              isUp ? "bg-[#fff]" : "bg-[#fff]"
                            } rounded-md mb-3 group border-[1px] border-[#dadce0]`}
                          >
                            <div className="flex flex-col flex-1 p-2 items-start relative">
                              <div
                                className={`h-[2px] w-full mb-2 rounded-md ${
                                  isUp ? "bg-[#137333]" : "bg-[#c5221f]"
                                }`}
                              />
                              <NewsStockSlider
                                key={index}
                                stockArr={stock}
                                storedsymbols={[]}
                                index={index}
                                watchlistCheck={true}
                              />
                              <div className="flex flex-col justify-between items-start mt-1">
                                <h3 className="text-[.875rem] font-semibold text-black mr-3 text-ellipsis">
                                  {stock.companyName}
                                </h3>
                                <div className="flex flex-col items-end mt-4">
                                  <p className="font-bold text-md text-black">
                                    ${stock.latestPrice}
                                  </p>
                                </div>
                              </div>
                              <div className="absolute right-0 bottom-0 flex justify-end items-end mt-4">
                                <button
                                  className="ml-4 text-gray-600 px-2 py-2 rounded hover:text-gray-800 flex items-center"
                                  onClick={() => {
                                    deleteWatchlist(stock);
                                  }}
                                >
                                  <svg
                                    viewBox="0 0 1024 1024"
                                    fill="red"
                                    className="w-10 h-10"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M667.8 362.1H304V830c0 28.2 23 51 51.3 51h312.4c28.4 0 51.4-22.8 51.4-51V362.2h-51.3z"
                                      fill="#CCCCCC"
                                    />
                                    <path
                                      d="M750.3 295.2c0-8.9-7.6-16.1-17-16.1H289.9c-9.4 0-17 7.2-17 16.1v50.9c0 8.9 7.6 16.1 17 16.1h443.4c9.4 0 17-7.2 17-16.1v-50.9z"
                                      fill="#CCCCCC"
                                    />
                                    <path
                                      d="M733.3 258.3H626.6V196c0-11.5-9.3-20.8-20.8-20.8H419.1c-11.5 0-20.8 9.3-20.8 20.8v62.3H289.9c-20.8 0-37.7 16.5-37.7 36.8V346c0 18.1 13.5 33.1 31.1 36.2V830c0 39.6 32.3 71.8 72.1 71.8h312.4c39.8 0 72.1-32.2 72.1-71.8V382.2c17.7-3.1 31.1-18.1 31.1-36.2v-50.9c0.1-20.2-16.9-36.8-37.7-36.8z m-293.5-41.5h145.3v41.5H439.8v-41.5z m-146.2 83.1H729.5v41.5H293.6v-41.5z m404.8 530.2c0 16.7-13.7 30.3-30.6 30.3H355.4c-16.9 0-30.6-13.6-30.6-30.3V382.9h373.6v447.2z"
                                      fill="#211F1E"
                                    />
                                    <path
                                      d="M511.6 798.9c11.5 0 20.8-9.3 20.8-20.8V466.8c0-11.5-9.3-20.8-20.8-20.8s-20.8 9.3-20.8 20.8v311.4c0 11.4 9.3 20.7 20.8 20.7zM407.8 798.9c11.5 0 20.8-9.3 20.8-20.8V466.8c0-11.5-9.3-20.8-20.8-20.8s-20.8 9.3-20.8 20.8v311.4c0.1 11.4 9.4 20.7 20.8 20.7zM615.4 799.6c11.5 0 20.8-9.3 20.8-20.8V467.4c0-11.5-9.3-20.8-20.8-20.8s-20.8 9.3-20.8 20.8v311.4c0 11.5 9.3 20.8 20.8 20.8z"
                                      fill="#211F1E"
                                    />
                                  </svg>
                                </button>
                              </div>
                            </div>
                          </li>
                        )}
                      </Draggable>
                    );
                  })}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      <div className="w-full p-4">
        <p className="font-semibold text-xs text-gray-500">
          Contact us: contact@opiniontrade.ai
        </p>
      </div>
    </div>
  );
}

export default Watchlist;
