import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import { useNavigate, useParams } from "react-router";
import TermsConditions from "./TermsConditions";
import PrivacyPolicy from "./PrivacyPolicy";
import ShareButton from "./ShareButton";

export default function Docs() {
  const [tab, settab] = useState(1);
  const params = useParams();
  let navigate = useNavigate();

  useEffect(() => {
    if (params?.docId === "terms-conditions") {
      settab(1);
    } else if (params?.docId === "privacy-policy") {
      settab(2);
    } else if (params?.docId === "share-button") {
      settab(3);
    }
  }, [params]);

  const renderTabContent = () => {
    if (tab === 1) {
      return <TermsConditions />;
    } else if (tab === 2) {
      return <PrivacyPolicy />;
    } else if (tab === 3) {
      return <ShareButton />;
    }
  };

  return (
    <main className={`flex flex-col w-screen h-screen bg-[#f3f4f6]`}>
      <div className="flex flex-col sm:flex-row w-screen h-screen">
        <div
          className={`bg-white px-5 w-full sm:w-[300px] border-r-[1px] border-gray-100/80 flex flex-col relative sm:static drop-shadow-2xl`}
        >
          <div className="flex flex-row justify-center items-center w-16 mx-auto my-4 sm:mt-6 p-2 bg-white rounded-2xl text-white z-20">
            <img
              src={require("../assets/logo.png")}
              loading="lazy"
              className="h-full w-full object-cover"
            />
            <p className="text-black relative ml-2 text-xl font-bold italic cursor-pointer">
              DOCS
            </p>
          </div>
          <nav
            className={`relative flex flex-row sm:flex-col py-0 sm:py-4 justify-center sm:justify-around z-20 sm:gap-0 gap-[10px]`}
          >
            <a
              onClick={() => navigate("/docs/terms-conditions")}
              className={`relative flex justify-center items-center w-auto h-10 sm:h-14 p-2 sm:p-4 ${
                tab === 1
                  ? "border-[#0d5446] border-2 "
                  : "border hover:bg-[#d0f5ed] hover:border-[#d0f5ed]"
              } rounded-lg mb-4 justify-center items-center cursor-pointer`}
            >
              <svg
                fill={tab === 1 ? "#0d5446" : "#8e8e8e"}
                className="sm:w-[30px] w-[20px] sm:h-[30px] h-[20px]"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M9 2L8.93417 2C8.04768 1.99995 7.28387 1.99991 6.67221 2.08215C6.01669 2.17028 5.38834 2.36902 4.87868 2.87868C4.36902 3.38835 4.17027 4.0167 4.08214 4.67221C3.9999 5.28387 3.99995 6.04769 4 6.93417L4 7V10.5V16.1707C2.83481 16.5825 2 17.6938 2 19C2 20.6569 3.34315 22 5 22H15.9966L16 22C17.6569 22 19 20.6569 19 19V9.00001V7.00001H19.5C20.8807 7.00001 22 5.88072 22 4.50001C22 3.11929 20.8807 2.00001 19.5 2.00001C19.3961 2.00001 19.2937 2.00634 19.1932 2.01865C19.1307 2.00641 19.0661 2 19 2H9ZM13.1707 20C13.0602 19.6872 13 19.3506 13 19V18H5C4.44772 18 4 18.4477 4 19C4 19.5523 4.44772 20 5 20H13.1707ZM19 5.00001H19.5C19.7761 5.00001 20 4.77615 20 4.50001C20 4.22386 19.7761 4.00001 19.5 4.00001C19.2239 4.00001 19 4.22386 19 4.50001V5.00001ZM8 7C8 6.44772 8.44772 6 9 6H14C14.5523 6 15 6.44772 15 7C15 7.55228 14.5523 8 14 8H9C8.44772 8 8 7.55228 8 7ZM9 10C8.44772 10 8 10.4477 8 11C8 11.5523 8.44772 12 9 12H14C14.5523 12 15 11.5523 15 11C15 10.4477 14.5523 10 14 10H9Z"
                />
              </svg>
              <div className="flex-1 ml-2">
                <p
                  className={`text-[12px] sm:text-[16px] ${
                    tab === 1
                      ? "text-[#0d5446] font-black"
                      : "text-[#8e8e8e] font-bold"
                  }`}
                >
                  Terms & Conditions
                </p>
              </div>
            </a>
            <a
              onClick={() => navigate("/docs/privacy-policy")}
              className={`relative flex justify-center items-center w-auto h-10 sm:h-14 p-2 sm:p-4 ${
                tab === 2
                  ? "border-[#0d5446] border-2 "
                  : "border hover:bg-[#d0f5ed] hover:border-[#d0f5ed]"
              } rounded-lg mb-4 justify-center items-center cursor-pointer`}
            >
              <svg
                fill={tab === 2 ? "#0d5446" : "#8e8e8e"}
                className="sm:w-[30px] w-[20px] sm:h-[30px] h-[20px]"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M12,22A17.5,17.5,0,0,0,21,6.7V6L12,2,3,6v.7A17.5,17.5,0,0,0,12,22ZM11,6h2V8H11Zm0,4h2v8H11Z" />
              </svg>
              <div className="flex-1 ml-2">
                <p
                  className={`text-[12px] sm:text-[16px] ${
                    tab === 2
                      ? "text-[#0d5446] font-black"
                      : "text-[#8e8e8e] font-bold"
                  }`}
                >
                  Privacy Policy
                </p>
              </div>
            </a>
            <a
              onClick={() => navigate("/docs/share-button")}
              className={`relative flex justify-center items-center w-auto h-10 sm:h-14 p-2 sm:p-4 ${
                tab === 3
                  ? "border-[#0d5446] border-2 "
                  : "border hover:bg-[#d0f5ed] hover:border-[#d0f5ed]"
              } rounded-lg mb-4 justify-center items-center cursor-pointer`}
            >
              <svg
                fill={tab === 3 ? "#0d5446" : "#8e8e8e"}
                className="sm:w-[30px] w-[20px] sm:h-[30px] h-[20px]"
                viewBox="0 0 16 16"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M11 6C12.6569 6 14 4.65685 14 3C14 1.34315 12.6569 0 11 0C9.34315 0 8 1.34315 8 3C8 3.22371 8.02449 3.44169 8.07092 3.65143L4.86861 5.65287C4.35599 5.24423 3.70652 5 3 5C1.34315 5 0 6.34315 0 8C0 9.65685 1.34315 11 3 11C3.70652 11 4.35599 10.7558 4.86861 10.3471L8.07092 12.3486C8.02449 12.5583 8 12.7763 8 13C8 14.6569 9.34315 16 11 16C12.6569 16 14 14.6569 14 13C14 11.3431 12.6569 10 11 10C10.2935 10 9.644 10.2442 9.13139 10.6529L5.92908 8.65143C5.97551 8.44169 6 8.22371 6 8C6 7.77629 5.97551 7.55831 5.92908 7.34857L9.13139 5.34713C9.644 5.75577 10.2935 6 11 6Z" />
              </svg>
              <div className="flex-1 ml-2">
                <p
                  className={`text-[12px] sm:text-[16px] ${
                    tab === 3
                      ? "text-[#0d5446] font-black"
                      : "text-[#8e8e8e] font-bold"
                  }`}
                >
                  Share Button
                </p>
              </div>
            </a>
          </nav>
          <div className="flex-1 justify-center flex items-end pb-4">
            <a
              onClick={() => navigate("/")}
              className="flex justify-center items-center px-3 py-1 rounded-md bg-gray-200 cursor-pointer hover:drop-shadow-md transition ease-in"
            >
              <span className="text-gray-600 text-[10px] font-bold">
                {"<"} Back to opiniontrade.ai
              </span>
            </a>
          </div>
        </div>
        <div className="flex-1 bg-white overflow-y-scroll p-10">
          {renderTabContent()}
        </div>
      </div>
    </main>
  );
}
