import React from "react";

export default function PrivacyPolicy() {
  return (
    <div className="font-sans text-gray-800 leading-relaxed">
      <header className="mb-8">
        <h1 className="text-3xl font-bold text-gray-900">
          Privacy Policy for OpinionTrade
        </h1>
        <p className="text-sm font-medium mt-2">
          <strong>Effective Date: July 12, 2024</strong>
        </p>
      </header>

      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-2">1. Introduction</h2>
        <p>
          We are committed to protecting the privacy of our users. This Privacy
          Policy explains how we collect, use, and disclose information through
          OpinionTrade.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-2">
          2. Information Collection and Use
        </h2>
        <p>
          For a better experience, while using our service, we may require you
          to provide us with certain personally identifiable information,
          including but not limited to your contact list. Your contacts will be
          uploaded to the OpinionTrade server to help you and others find
          friends. The information that we request will be retained by us and
          used as described in this privacy policy.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-2">3. Log Data</h2>
        <p>
          We want to inform you that whenever you use our service, in a case of
          an error in the app, we collect data and information (through
          third-party products) on your phone called Log Data. This Log Data may
          include information such as your device Internet Protocol (“IP”)
          address, device name, operating system version, the configuration of
          the app when utilizing our service, the time and date of your use of
          the service, and other statistics.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-2">4. Service Providers</h2>
        <p>
          We may employ third-party companies and individuals due to the
          following reasons:
        </p>
        <ul className="list-disc ml-6">
          <li>To facilitate our service;</li>
          <li>To provide the service on our behalf;</li>
          <li>To perform service-related services; or</li>
          <li>To assist us in analyzing how our service is used.</li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-2">5. Security</h2>
        <p>
          We value your trust in providing us with your Personal Information,
          thus we are striving to use commercially acceptable means of
          protecting it. But remember that no method of transmission over the
          internet, or method of electronic storage is 100% secure and reliable,
          and we cannot guarantee its absolute security.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-2">6. Links to Other Sites</h2>
        <p>
          Our service may contain links to other sites. If you click on a
          third-party link, you will be directed to that site. Note that these
          external sites are not operated by us. Therefore, we strongly advise
          you to review the Privacy Policy of these websites. We have no control
          over and assume no responsibility for the content, privacy policies,
          or practices of any third-party sites or services.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-2">7. Children’s Privacy</h2>
        <p>
          Our services do not address anyone under the age of 13. We do not
          knowingly collect personally identifiable information from children
          under 13.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-2">8. Data Use and Resale</h2>
        <p>
          As of now, OpinionTrade uses contact data and other personal
          information solely to facilitate user connections within the app. We
          do not sell or otherwise distribute your personal information to third
          parties.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-2">
          9. Changes to This Privacy Policy
        </h2>
        <p>
          We may update our Privacy Policy from time to time. Thus, you are
          advised to review this page periodically for any changes. We will
          notify you of any changes by posting the new Privacy Policy on this
          page.
        </p>
      </section>

      <section>
        <h2 className="text-xl font-semibold mb-2">10. Contact Us</h2>
        <p>
          If you have any questions or suggestions about our Privacy Policy, do
          not hesitate to contact us at{" "}
          <a
            href="mailto:contact@opiniontrade.ai"
            className="text-blue-500 underline"
          >
            contact@opiniontrade.ai
          </a>
          .
        </p>
      </section>
    </div>
  );
}
