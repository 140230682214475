import { onValue, ref, set, update, get } from "firebase/database";
import { useEffect, useState, useRef, useCallback, createRef } from "react";
import { database } from "../../firebase";
import Sidebar from "../Sidebar";
import { actionTypes } from "../../reducer";
import { useStateValue } from "../../StateProvider";
import NewsCard from "../../components/NewsCard";
import SharedNewsCard from "../../components/SharedNewsCard";
import TestAd from "../../components/TestAd";
import MarketMovers from "../../components/MarketMovers";
import Header from "../../components/Header";
import "../styles/Overlayblock.css";
import { isMobile } from "react-device-detect";

function TrendingTab() {
  const [loading, setloading] = useState(true);
  const [news, setnews] = useState([]);
  const [readnews, setreadnews] = useState([]);
  const [bookmarknews, setbookmarknews] = useState([]);
  const [jointrendingcount, setJoinTrendingCount] = useState(0);
  const [codereceived, setcodereceived] = useState(false);
  const [phonenumber, setphonenumber] = useState("");
  const [code, setcode] = useState("");
  const recaptchaRef = useRef(null);

  const [
    {
      user,
      tab,
      chat,
      send,
      sharednews,
      selectedstocks,
      config,
      search,
      displayNews,
      loginClicked,
    },
    dispatch,
  ] = useStateValue();
  const [storedsymbols, setstoredsymbols] = useState([]);
  const [displaynews, setdisplaynews] = useState(false);
  const [pageNumber, setPageNumber] = useState(1); // Track the page number
  const [prevPage, setPrevPage] = useState(0); // storing prev page number
  const [lastList, setLastList] = useState(false);
  const listInnerRef = useRef();
  const [hasMoreNews, setHasMoreNews] = useState(true);
  const [showFetchMore, setShowFetchMore] = useState(false);
  const [showLoginPrompt, setShowLoginPrompt] = useState(false);
  useEffect(() => {
    setloading(true);
    setnews([]);
    getNews();
  }, [tab, selectedstocks]);

  // Load count from Firebase when the component mounts
  useEffect(() => {
    const fetchCount = async () => {
      const countRef = ref(database, "/webanalytics/JoinTrendingCount");
      const snapshot = await get(countRef);

      if (snapshot.exists()) {
        setJoinTrendingCount(snapshot.val());
      } else {
        console.log("Count does not exist in Firebase.");
      }
    };

    fetchCount();
  }, []);

  useEffect(() => {
    setloading(true);
    setnews([]);
    if (user) {
      const userInfo = ref(database, `/users/${user.phoneNumber}/`);
      onValue(
        userInfo,
        (snapshot) => {
          if (!snapshot.exists) {
            setreadnews([]);
            setbookmarknews([]);
          } else {
            let snapVal = snapshot.val();
            if (
              snapVal.bookmarknews !== undefined &&
              snapVal.bookmarknews !== null
            ) {
              setreadnews(Object.keys(snapVal.readnews));
              setbookmarknews(Object.keys(snapVal.bookmarknews));
            }
          }
        },
        (err) => {
          setreadnews([]);
          setbookmarknews([]);
        }
      );
      const watchlistInfo = ref(
        database,
        `/users/${user.phoneNumber}/watchlist`
      );
      onValue(
        watchlistInfo,
        (snapshot) => {
          if (!snapshot.exists) {
            setstoredsymbols([]);
          } else {
            let snapVal = snapshot.val();
            if (snapVal !== undefined && snapVal !== null) {
              setstoredsymbols(Object.keys(snapVal));
            }
          }
        },
        (err) => {
          console.log("error getting watchlist", err);
          setstoredsymbols([]);
        }
      );
    } else {
      // dispatch({
      //   type: actionTypes.SET_TAB,
      //   tab: 1,
      // });
      setreadnews([]);
      setbookmarknews([]);
    }
    getNews();
  }, [user]);

  useEffect(() => {
    if (!search.enabled) {
      getNews();
    }
  }, [search.enabled]);

  const getNews = () => {
    // Extract symbols from selectedstocks
    let stocks = selectedstocks.map((stock) => stock.symbol).join("&ticker=");
    let url = `${
      process.env.REACT_APP_SERVER_ADDRESS
    }/opinion/news?sort=refCountTime&pageNumber=0&pageSize=50${
      stocks ? "&ticker=" + stocks : ""
    }`;

    setloading(true);
    fetch(url)
      .then((resp) => resp.json())
      .then((respJson) => {
        if (respJson.length > 0) {
          setnews(respJson);
          //setHasMore(true);
        } else {
          setnews([]);
        }
        setloading(false);
      })
      .catch((error) => {
        console.log("errerrr", error);
        setnews([]);
        setloading(false);
        //setHasMore(false);
      });
  };

  useEffect(() => {
    // Trigger the login prompt when the user is not logged in
    if (!user) {
      setShowLoginPrompt(true);
    } else {
      setShowLoginPrompt(false);
    }
  }, [user]);

  const getMoreNews = () => {
    // Extract symbols from selectedstocks
    let stocks = selectedstocks.map((stock) => stock.symbol).join("&ticker=");
    let url = `${
      process.env.REACT_APP_SERVER_ADDRESS
    }/opinion/news?sort=refCountTime&pageNumber=${pageNumber}&pageSize=50${
      stocks ? "&ticker=" + stocks : ""
    }`;

    //setloading(true);
    fetch(url)
      .then((resp) => resp.json())
      .then((respJson) => {
        if (!respJson.length) {
          setLastList(true);
          return;
        }
        if (news.length === 0) {
          setHasMoreNews(false);
        } else {
          setPrevPage(pageNumber);
          setnews((prevNews) => [...prevNews, ...respJson]);
          setloading(false);
          //setShowFetchMore(false);
        }
      })
      .catch((error) => {
        console.log("errerrr", error);
        //setnews([]);
        setloading(false);
        setLastList(true);
      });
  };

  const onScroll = (e) => {
    const currentScroll = e.target.scrollTop;
    setScrollPosition(currentScroll);

    // Trigger login prompt on scroll past a certain point, e.g., 100px
    if (!user && currentScroll > 500) {
      setShowLoginPrompt(true);
    }

    if (user) {
      if (listInnerRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
        if (scrollTop + clientHeight === scrollHeight && hasMoreNews) {
          setShowFetchMore(true);
          setPageNumber(pageNumber + 1);
          getMoreNews();
        }
      }
    }
  };

  const insertAds = (config, data) => {
    const newData = [...data];
    if (
      config &&
      config.showBannerAds &&
      config.showBannerAds.newsListIndices
    ) {
      const bannerAdIndices = Array.isArray(
        config.showBannerAds.newsListIndices
      )
        ? config.showBannerAds.newsListIndices.map((index) =>
            parseInt(index, 10)
          )
        : [parseInt(config.showBannerAds.newsListIndices, 10)];

      if (config.showBannerAds.enabled) {
        bannerAdIndices.forEach((index) => {
          if (index >= 0 && index <= newData.length) {
            newData.splice(index, 0, { ad: true });
          }
        });
      }
    }

    /*
    const bannerAdIndices = ['2', '6']
    if (config.showBannerAds.enabled) {
      bannerAdIndices.forEach(index => {
        if (index >= 0 && index <= newData.length) {
          newData.splice(index, 0, { ad: true });
        }
      });
    }
    */
    return newData;
  };

  useEffect(() => {
    if (isMobile && codereceived) {
      setnumberloading(false);
    }
  }, [isMobile, codereceived]);

  if (loading) {
    return (
      <div
        className={`flex flex-col flex-1 overflow-y-scroll scrollbar-hide bg-white border-r-0 sm:border-r-[5px] cursor-pointer ${
          user ? "" : "rounded-r-3xl"
        }`}
      >
        <MarketMovers storedsymbols={storedsymbols} />
        <Sidebar mobile={true} />
        {/*renderDisplayNews()*/}
        <Header
          title="Trending News"
          icon={
            <svg
              height="25px"
              width="25px"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 384 512"
              fill={
                config.typeofFirebaseDB !== "production" ? "#0d5446" : "#d54446"
              }
            >
              <path d="M384 319.1C384 425.9 297.9 512 192 512s-192-86.13-192-192c0-58.67 27.82-106.8 54.57-134.1C69.54 169.3 96 179.8 96 201.5v85.5c0 35.17 27.97 64.5 63.16 64.94C194.9 352.5 224 323.6 224 288c0-88-175.1-96.12-52.15-277.2c13.5-19.72 44.15-10.77 44.15 13.03C215.1 127 384 149.7 384 319.1z" />
            </svg>
          }
        />
        <div className="flex justify-center items-center h-screen">
          {/* Spinner */}
          <svg
            role="status"
            className="inline w-10 h-10 mr-2 text-gray-200 animate-spin fill-blue-600"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9765 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9765 100 50.5908ZM9.08136 50.5908C9.08136 73.1895 27.4013 91.5095 50 91.5095C72.5987 91.5095 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67217 50 9.67217C27.4013 9.67217 9.08136 27.9921 9.08136 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5532C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7232 75.2124 7.41289C69.5422 4.10259 63.2754 1.94025 56.7222 1.05125C51.7664 0.367118 46.7345 0.446408 41.8192 1.27873C39.297 1.69443 37.8266 4.19778 38.4637 6.62326C39.1008 9.04874 41.5888 10.4711 44.0875 10.1071C47.8291 9.52487 51.6295 9.52678 55.3674 10.1123C60.8745 10.9557 66.1352 13.0003 70.7281 16.0997C75.321 19.1991 79.149 23.2737 81.8832 28.0785C84.1122 31.9221 85.7086 36.1578 86.603 40.571C87.1583 43.0158 89.5421 44.6366 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
          <p className="text-gray-600 mt-4">Loading...</p>
        </div>
      </div>
    );
  }
  const dataWithAds = insertAds(config, news);
  return (
    <div
      className={`flex-1 scrollbar bg-white border-r-0 sm:border-r-[5px] cursor-pointer ${user ? "" : "sm:rounded-r-3xl"} ${user
          ? send.enabled
            ? "overflow-y-hidden"
            : "overflow-y-hidden"
          : "overflow-y-hidden"}`}
      style={{
        maxHeight: "100vh",
        overflowY: /*user ? */ "scroll" /*: "hidden"*/,
      }}
      onScroll={onScroll}
      ref={listInnerRef}
    >
      <>
        <MarketMovers storedsymbols={storedsymbols} />
        <Sidebar mobile={true} />
        {/*renderDisplayNews()*/}
        <Header
          title="Trending News"
          icon={<svg
            height="25px"
            width="25px"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 384 512"
            fill="#0d5446"
          >
            <path d="M384 319.1C384 425.9 297.9 512 192 512s-192-86.13-192-192c0-58.67 27.82-106.8 54.57-134.1C69.54 169.3 96 179.8 96 201.5v85.5c0 35.17 27.97 64.5 63.16 64.94C194.9 352.5 224 323.6 224 288c0-88-175.1-96.12-52.15-277.2c13.5-19.72 44.15-10.77 44.15 13.03C215.1 127 384 149.7 384 319.1z" />
          </svg>} />
        <>
          <ul className="mt-0 relative">
            {sharednews.newsTitle && (
              <SharedNewsCard
                key={"sharednews"}
                news={sharednews}
                bookmarknews={bookmarknews}
                readnews={readnews}
                storedsymbols={storedsymbols} />
            )}
            {news &&
              news.map((newsItem, index) => (
                <div key={index} className={`${index >= 3 && !user ? "blurred-item" : ""} transition-all duration-300`}>
                  {newsItem.ad ? null : (
                    <NewsCard
                      news={newsItem}
                      bookmarknews={bookmarknews}
                      readnews={readnews}
                      storedsymbols={storedsymbols} />
                  )}

                  {/* Insert "Login to view the rest!" after the third item */}
                  {index === 2 && !user && (
                    <div className="flex justify-center items-center my-4 text-gray-600">
                      <button
                        onClick={() => {
                          if (!isMobile) {
                            dispatch({
                              type: actionTypes.SET_LOGIN_CLICKED,
                              loginClicked: true,
                            });
                          } else {
                            dispatch({
                              type: actionTypes.SET_TAB,
                              tab: 7,
                            });
                          }
                        } }
                        style={{
                          marginTop: 20,
                          padding: "10px 20px",
                          backgroundColor: "#008000",
                          color: "#fff",
                          border: "none",
                          borderRadius: 20,
                          fontSize: "1rem",
                          cursor: "pointer",
                        }}
                      >
                        Click Here to view the rest!
                      </button>
                    </div>
                  )}

                  {/* Only render "Fetching more news..." after the last news item */}
                  {index === news.length - 1 && hasMoreNews && !lastList && (
                    <div className="flex flex-col justify-center items-center py-4 text-gray-500">
                      <div>Fetching more news...</div>
                      <svg
                        role="status"
                        className="inline w-10 h-10 mt-4 text-gray-200 animate-spin fill-blue-600"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9765 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9765 100 50.5908ZM9.08136 50.5908C9.08136 73.1895 27.4013 91.5095 50 91.5095C72.5987 91.5095 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67217 50 9.67217C27.4013 9.67217 9.08136 27.9921 9.08136 50.5908Z"
                          fill="currentColor" />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5532C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7232 75.2124 7.41289C69.5422 4.10259 63.2754 1.94025 56.7222 1.05125C51.7664 0.367118 46.7345 0.446408 41.8192 1.27873C39.297 1.69443 37.8266 4.19778 38.4637 6.62326C39.1008 9.04874 41.5888 10.4711 44.0875 10.1071C47.8291 9.52487 51.6295 9.52678 55.3674 10.1123C60.8745 10.9557 66.1352 13.0003 70.7281 16.0997C75.321 19.1991 79.149 23.2737 81.8832 28.0785C84.1122 31.9221 85.7086 36.1578 86.603 40.571C87.1583 43.0158 89.5421 44.6366 93.9676 39.0409Z"
                          fill="currentFill" />
                      </svg>
                      <p className="text-gray-600 mt-4">Loading...</p>
                    </div>
                  )}
                </div>
              ))}
          </ul>
        </>
      </>
      {/* Login Prompt Overlay - Appears from bottom and covers half the screen */}
      {showLoginPrompt && (
        <div className={isMobile ? "login-overlay-mobile animate-slide-up" : "login-overlay animate-slide-up"}>
          <div className="login-overlay-content">
            <button
              onClick={() => {
                if (!isMobile) {
                  setJoinTrendingCount((prevCount) => {
                    const newCount = prevCount + 1;

                    // Update Firebase with the new count
                    update(ref(database, `/webanalytics/`), {
                      JoinTrendingCount: newCount,
                    })
                      .then(() => {
                        console.log("Firebase updated successfully");
                      })
                      .catch((error) => {
                        console.error("Error updating Firebase:", error);
                      });
                    return newCount; // Ensure the state is updated correctly
                  });

                  dispatch({
                    type: actionTypes.SET_LOGIN_CLICKED,
                    loginClicked: true,
                  });
                } else {
                  dispatch({
                    type: actionTypes.SET_TAB,
                    tab: 7
                  });
                }
              } }
              style={{
                marginTop: 20,
                padding: "10px 20px",
                backgroundColor: "#008000",
                color: "#fff",
                border: "none",
                borderRadius: 20,
                fontSize: "1rem",
                cursor: "pointer",
              }}
            >
              Click Here to Join OpinionTrade!
            </button>
            {!isMobile ? (
              <>
                <h2
                  style={{
                    fontSize: "1.5rem",
                    fontWeight: "bold",
                  }}
                >
                  Welcome! 🌟 Join The First Social Network For Investors
                  (COMPLETELY FREE - AI POWERED)
                </h2>
                <p
                  style={{
                    marginTop: "10px",
                    fontSize: "1rem",
                    lineHeight: 1.5,
                  }}
                >
                  Enter your phone number to connect with your friends and start
                  making smarter investment decisions today! Join a community that's
                  already profiting.
                </p>
              </>
            ) : null}
            <div>
              <div ref={recaptchaRef}></div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default TrendingTab;
